import React from "react";

export default function CreateError() {
  
  function renderError() { 
    var hello;
    return hello.foo;
  }

  return renderError();
}