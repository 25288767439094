const dev = {
  MAX_ATTACHMENT_SIZE: 5000000,
  STRIPE_KEY: "pk_test_akqtjciHjOmMHMZM22JvR6HZ00kjumoQXA",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-uploads1917"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://bxhag3jak7.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_9ElbSHqfT",
    APP_CLIENT_ID: "7janfsf55onn1nf776b8rl6vn3",
    IDENTITY_POOL_ID: "us-east-1:40c3bf5f-f054-4fe4-8cd2-0368be0b2580"
  }
};

const prod = {
  MAX_ATTACHMENT_SIZE: 5000000,
  STRIPE_KEY: "pk_test_akqtjciHjOmMHMZM22JvR6HZ00kjumoQXA",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-uploads1917"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://vei6a2jj4m.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_UJDZz5vEG",
    APP_CLIENT_ID: "22mb83gbockc3svuiohhiti0ge",
    IDENTITY_POOL_ID: "us-east-1:19582e8f-3a05-43e7-a17b-eeb7b38f59e5"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};